.App {
  text-align: center;
}
.HomeContainer {
  width: 100%;
  min-height: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #f5f5f5;
}
.CCAppBar__ServiceName {
  margin-left: 1rem;
}
.CCAppBar__Right {
  justify-content: flex-end;
  flex-grow: 1;
  text-align: right;
  margin-right: 1rem;
}
